body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headerDate{
  font-weight: normal !important;
  padding-left: 1em;
}

.sessionHeader{
  background-color: black;
  color: white;
}

.sessionTable{
  border: 1px black;
}

.container{ 
  padding-top: 10px;
}

.card{
  margin-top: .5em;
  background-color: #effcff !important;
}

#root{
  background-color: #cbf2ff !important;
  height: 100vh;
}

/* Force full height */
html{
  min-height:100%;
  height:100vh;
}
body{
  min-height:100%;
  height:100vh;
}

.pagination{
  padding-left: 2em !important;
}